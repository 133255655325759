
.example-two{
    display: none;
}
@media screen and (max-width: 600px){
    .example-two{
        display: block;
    } 
}


.scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.example-two-header .logo {
    width: 0%;
}
.example-two-header nav {
    width: 100%;
}
.example-three .logo, .example-three nav {
    width: 100%;
}
.example-three .nav-item {
    color: #f6f7ee;
}
header {
    background: transparent;
}
.example-one-header, .example-two-header {
    border-radius: 3px;
}
.example-three-header {
    border-radius: 3px 3px 0 0;
}
.example-three nav {
    background: #727c87;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    border-radius: 0 0 3px 3px;
}
.logo {
    text-align: center;
    font-weight: 700;
    color: #727c87;
    border-right: 1px solid rgba(114, 124, 135, .4);
    padding: 12px 24px 13px;
}
.nav-item {
    padding: 12px 16px 13px;
}

.logo, .nav-item {
    font-size: 14px;
}
.logo, .nav-item, .vertical-align-middle {
    display: inline-block;
    vertical-align: middle;
}
.title {
    margin: 24px 0 6px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #999;
}
.scroll::-webkit-scrollbar {
    display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

.bio {
  text-align: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.anmate-text {
  font-style: normal;
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 3.5rem;
  padding: 50px;
  display: inline-block;
  transform: translateX(100%);
  white-space: nowrap;
  color: #edeff3;
}
.anmate-text span {
  font-family: Caveat;
  font-style: normal;
  font-weight: normal;
  font-size: 4.5rem;
  line-height: 4.5rem;
  color: #656971;
}
.animated3 {
  display: block;
  animation: fade linear 0.5s;
}
.animated9 {
  display: block;
  animation: fade linear 2s;
}
.animated4 {
  display: block;
  animation: fade2 linear 0.5s;
}
.animated {
  display: block;
  animation: fade linear 0.5s;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animated2 {
  display: block;
  animation: fade2 linear 1s;
}
@keyframes fade2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.text-moving {
  animation: moveText linear 15s -3s forwards infinite;
}

@keyframes moveText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-150%);
  }
}

[data-aos="new"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="new"].aos-animate {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  [data-aos="new"] {
    transform: translateY(30px);
  }
  [data-aos="new"].aos-animate {
    transform: translateY(0);
  }
}

body {
  position: static;
  width: 100vw;
  overflow-x: hidden;
  background-color: #24292a;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}
* {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.topicon {
  position: fixed;
  z-index: 9999999;
  margin-top: 3.9vh;
  margin-left: 0.5vw;
}
.menu-collapsed {
  display: none;
}
.topbar {
  display: block;
}

.main {
  background-color: #24292a;

  width: 100vw;
}

.background {
  margin-left: 10vw;
  margin-right: 10vw;
}
.navb {
  background-color: #24292a;
  position: fixed;
  width: 100%;
  z-index: 9999999;
  height: 11.9vh;
  box-shadow: 0px 0px 30px rgba(25, 28, 30, 0.5);
}
.navb2 {
  background-color: #24292a;
  position: fixed;
  width: 100%;
  z-index: 9999999;
  height: 11.9vh;
}
.navbars {
  display: flex;
  float: right;
  margin-right: 13vw;
  margin-top: 5.3vh;
  text-decoration: none;
}
.navbars li {
  text-decoration: none;
}
.tap {
  text-decoration: none;
  width: auto;

  margin-left: 6.6vw;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 0.875rem;
  /* identical to box height, or 100% */

  /* Text/Alternate-light-grey */

  color: #edeff3;
}
/* first box*/
.container-one {
  display: flex;
  overflow: hidden;
  height: 100vh;
}
.item-one {
  padding-right: 5%;
  width: 43%;
  font-size: 1rem;
}
.item-two {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;

  overflow: hidden;
}
.item-two img {
  width: 100%;
  height: auto;
  margin-left: -1vw;
}
.t-text {
  font-size: 1em;
  line-height: 1em;
  overflow: hidden;
  color: #939aa9;
}
.bt-n {
  display: flex;
  margin-top: 6.45vh;
}
.contact {
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #656971;
  margin-top: 12vh;
}
.c2 {
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #edeff3;

  text-decoration-line: underline;
}

.item-one h1 {
  margin-top: 30vh;
  font-family: Caveat;

  font-size: 3rem;
  line-height: 3rem;
  color: #edeff3;
  margin-bottom: 2.3vh;
}

.talkbutton {
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 4px;
  font-size: 0.9rem;
  width: 8vw;
  height: 46px;
}

.talkbutton button {
  background: linear-gradient(97.15deg, #793599 -11.99%, #133a9e 109.23%);
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1rem;
  border: transparent;
  color: #edeff3;
  width: 7.7vw;
  height: 46px;
}

.talkbutton button:hover {
  font-size: 1rem;
  width: 7.9vw;
  height: 46px;
}

.talkbutton:hover {
  width: 7.9vw;
  height: 48px;
  margin-right: -0.2vw;
  margin-top: -0.1vh;
}
.b2:hover {
  transform: scale(1.05);
  color: #fcfcfc;
}
.b2 {
  background: transparent;
  border: transparent;
  width: 14.3vw;
  height: 46px;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #bfc6eb;
}

/* secound box*/
.container-two {
  color: #939aa9;
  width: 45vw;

  margin-top: 10.2vh;
}
.container-two h2 {
  width: 43vw;

  font-size: 2rem;
  line-height: 2.75rem;
  /* or 137% */

  /* Text/Light-20 */

  color: #939aa9;
}

.container-three {
  display: flex;
  opacity: 1;
}
.item-3 {
  color: #edeff3;
  opacity: 1;
}
.item-3 h1 {
  width: 30vw;
  height: 52.6vh;
  opacity: 1;

  color: #363a40;
  margin-top: 33.7vh;

  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4.25rem;
  /* or 121% */

  letter-spacing: 0.0025em;
}

.item-3 p {
  width: 38vw;

  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 13vh;

  margin-left: 8.6vw;
  color: #939aa9;
}
.belowtext {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 7.4vh;

  color: #fcfcfc;
}
/*third box*/

.count {
  width: 100% !important;
  text-align: center;
  justify-content: center;
  margin-left: 0vw;
}
.count2 {
  display: none;
}

.countext {
  height: 30vh;
}
.countext h1 {
  font-weight: bold;
  font-size: 4.5rem;
  line-height: 4.5rem;
  /* identical to box height, or 100% */

  /* Text/Light */

  color: #fcfcfc;
}
.countext p {
  font-size: 1rem;
  line-height: 1.25rem;
  /* or 125% */

  text-align: center;

  /* Text/Light-20 */

  color: #939aa9;
}
/*fourth box*/

.box1 {
  position: relative;
}

@media screen and (min-width: 600px) {
  .box1 {
    margin-bottom: 176px;
    margin-left: 5vw;
    width: 648px;
    position: relative;
    height: 575px;
  }
}
.opacityhigh {
  opacity: 1;
}
.opacitylow {
  opacity: 0.1;
}
.b3 {
  margin-left: 33vw;
}
.b3 h2 {
  text-align: right;
  float: right;
}
.containner {
  height: auto;
  width: 100%;
  display: block;
}
.btext {
  height: 7.3vh;
  overflow: hidden;
}
.btext h2 {
  font-size: 1.5em;
  line-height: 2rem;
  margin-top: 2.3vh;
  width: 37.8vw;
  color: #edeff3;
}
.btext span {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #6c778c;
}
.box1 img {
  width: 45vw;
  height: auto;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-self: center;

  justify-content: center;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}
.box1 img:hover {
  transform: scale(1.15);
}
.box1 div {
  width: 45vw;
  height: auto;
  overflow: hidden;
}

.number {
  font-size: 1.5rem;
  line-height: 1.5rem;
  opacity: 0.5;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  transform: rotate(-90deg);
  /* Text/Dark-grey */
  position: absolute;
  right: -3vw;
  bottom: 0vh;
  z-index: 99999999999;
}
.number2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  transform: rotate(-90deg);
  /* Text/Dark-grey */
  position: absolute;
  left: -3vw;
  bottom: 0vh;
  z-index: 99999999999;
  opacity: 0.5;
}
.number3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  transform: rotate(-90deg);
  /* Text/Dark-grey 

margin-left: 43vw;
*/
  position: absolute;

  right: -3vw;

  bottom: 0vh;
  z-index: 99999999999;
  opacity: 0.5;
}
.number4 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  transform: rotate(-90deg);
  /* Text/Dark-grey */
  position: absolute;
  left: -3vw;
  bottom: 0vh;
  z-index: 99999999999;
  opacity: 0.5;
}
.ex {
  bottom: 10vh !important;
}
.an {
  margin-top: 40vh;
}
.dis {
  width: 25vw;
  font-size: 1.5rem;
  line-height: 2rem;

  color: #939aa9;
}
.head {
  font-size: 3.5rem;
  line-height: 4.5rem;
  width: 25vw;
  margin-top: 10vh;
  color: #edeff3;
}
.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 6.4vh;
}
.n {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-right: 5.9vw;
  position: static;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-bottom: 1vh;
}
.frame {
  margin-top: 8.4vh;
}
.n button {
  position: static;
  background: transparent;
  border: transparent;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.n button:hover {
  color: #c1c4d6;
}
.invisible {
  display: none;
}
.invisible a {
  display: none;
}
.hoverTopTxt {
  text-align: center;
}
.hoverTopTxt h1 {
  font-size: 1.6rem;
  color: #fcfcfc;
}
.hoverTopTxt p {
  color: #6c778c;
  margin-bottom: 1rem;
  height: 20%;
}
.hoverBg {
  width: 100%;
  background-color: black;
}
.noHoverBg {
  width: 100%;
}
.hoverbutton {
  position: absolute;
  z-index: 999999999;
  height: 100% !important;
  align-items: center;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverbutton2 {
  position: absolute;
  z-index: 999999999;
  top: 33%;
  left: 33%;
}

.hoverbtn button:hover {
  font-size: 0.9rem;
  width: 9.2vw !important;
  height: 46px !important;
}

.hoverbtn:hover {
  font-size: 0.9rem;
  width: 9.4vw !important;
  height: 48px !important;
  background: linear-gradient(90deg, #f0f1f3 -2.1%, rgba(45, 49, 54, 0) 95.8%);
}

.hoverbtn {
  width: 9vw !important;
  height: 46px !important;
  margin-top: 4.5vh;
  border-radius: 5px;
  padding: 1px;
  display: inline-block;
  background: linear-gradient(90deg, #f0f1f3 -1.9%, rgba(45, 49, 54, 0) 95.8%);
}

.hoverbtn button {
  text-decoration: none;
  background: linear-gradient(97.15deg, #793599 -11.99%, #133a9e 109.23%);
  box-shadow: transparent;
  border-radius: 5px;
  font-size: 0.85rem;
  line-height: 1rem;
  height: 100%;
  width: 100%;
  color: #edeff3;
  border: transparent;
}

.hoverbtn a {
  text-decoration: transparent;
  width: 13.5vw !important;
  height: 7.7vh !important;
}

.black {
  color: #656971;
}

.white {
  color: #bfc6eb;
  border-bottom: #bfc6eb;
  border: 2px;
}
.an-text {
  margin-top: 2vh;
}

/* 5th fase*/
.interests {
  margin-left: 11vw;
  margin-right: 11vw;
}
.table1 {
  display: flex;
  width: 100%;
}
.one {
  margin-top: 57vh;
  overflow: hidden;
}
.one img {
  width: 17vw !important;
}
.two {
  margin-left: 4vw;
  margin-top: 40vh;
  overflow: hidden;
}
.two img {
  width: 9vw;
}
.three {
  margin-left: 9vw;
  margin-top: 27vh;
  overflow: hidden;
}
.three img {
  width: 12vw;
}
.four {
  margin-left: 3vw;
  margin-top: 55vh;
  overflow: hidden;
}
.four {
  width: 24vw;
}
.table2 {
  margin-top: 9.4vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 65vw;
  margin-left: 7.5vw;
  margin-right: 7.5vw;
}
.table2 h1 {
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: #fcfcfc;
}
.table2 p {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: #939aa9;
}

.table3 {
  display: flex;
}

.five {
  width: 24vw;
  margin-top: 10vh;
  overflow: hidden;
}

.five img {
  width: 24vw;
}

.six {
  width: 24vw;

  overflow: hidden;
  margin-left: 8vw;
  margin-top: 29vh;
}
.six img {
  width: 24vw;
}
.seven {
  width: 16vw;

  margin-left: 6vw;
  margin-top: 5vh;
  overflow: hidden;
}
.seven img {
  width: 16vw;
}

/* footer */
.bottom {
  margin-left: 10vw;
  margin-right: 10vw;
}

.fouter {
  display: flex;

  margin-top: 21.6vh;
}

.fo1 h2 {
  width: 25vw;
  height: 12.7vh;
  font-size: 1.5rem;
  line-height: 2.125rem;

  color: #edeff3;
}
.fo1 p {
  font-size: 1rem;
  line-height: 1rem;
  color: #656971;
}
.idea {
  margin-bottom: 0.9vh;
}
.span {
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-decoration-line: underline;

  color: #edeff3;
}
.fo2 p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #7c7f86;
}
.fo2 {
  margin-left: 27vw;
  width: 25vw;
}

.back a {
  text-decoration: none;
  color: #939AA9;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.back img {
  text-align: center;
  margin-left: 12vw;
  width: 1rem;
  margin-bottom: 2px;
}
.icons {
  display: flex;
  margin-top: 3.2vh;
}
.icons a {
  margin-right: 1.7rem;
}
.icons i {
  color: #edeff3;
  font-size: 1.7rem;
}
.icons i:hover {
  color: #7c7f86;
}

.icons img:hover {
  opacity: 0.3;
}
.last {
  text-align: center;
  margin-top: 8.8vh;
}
.copy {
  font-size: 1rem;
  line-height: 1rem;
  /* identical to box height, or 100% */
  text-align: center;
  /* Text/Dark-20 */

  color: #363a40;
}

/*model*/
.modal-content {
  background: #2d3136;
  vertical-align: middle;
  align-items: center;
  justify-self: center;

  border-radius: 20px !important;
}
.model {
  padding: 0;
  border-radius: 20px !important;
}
.modal-fullscreen {
  width: 80vw;
  margin-top: 10vh;
  margin-left: 10vw;
  height: 80vh;
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  padding: 1px;

  box-shadow: -10px -10px 40px rgba(212, 211, 226, 0.15),
    10px 10px 40px rgba(12, 13, 13, 0.7),
    inset -5px -5px 24px rgba(179, 178, 186, 0.05),
    inset 5px 5px 24px rgba(12, 13, 13, 0.25);
  border-radius: 20px !important;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.cross {
  position: absolute;
  right: 2.2vw;
  top: 3.5vh;
  z-index: 99999999999999;
}
.modal-header {
  border: transparent;
}
.modal-footer {
  border: transparent;
}

.submitimg {
  width: 31.4vw;
  height: 42.5vh;

  text-align: center;
  padding: 0;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.modal-body {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}
.from {
  width: 30vw;
  margin-left: 4.4vw;
  margin-top: 5.3vh;
}
.texttop h1 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #edeff3;
}
.top {
  display: none;
}
.im {
  display: flex;
}
.texttop p {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  width: 29.6vw;
  color: #939aa9;
}
.inputtext label {
  font-size: 0.75rem;
  line-height: 0.75rem;
  /* identical to box height, or 100% */

  /* Text/Light-20 */

  color: #939aa9;
}

.withInputStyle1 {
  background: linear-gradient(91.42deg, #8346c2 -1.9%, #243a9e 95.8%);
  border-radius: 5px;
  margin-top: 1vh;
  display: inline-block;
  font-size: 2.4vh;
  padding: 1px;
  text-decoration: none;
}

.noInputStyle1 {
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 5px;
  margin-top: 1vh;
  display: inline-block;
  font-size: 2.4vh;
  padding: 1px;
  text-decoration: none;
}

.noInputStyle1 input {
  background: #2d3136;
  border: transparent;
  display: block;
  width: 25vw;
  height: 6.2vh;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #fcfcfc;
  padding: 0em 1.4vw;
  border-radius: 6px;
}

input:focus {
  background: #2d3136;
  border: transparent;
  outline: none;
}

textarea:focus {
  background: #2d3136;
  border: transparent;
  outline: none;
}

.withInputStyle1 input {
  background: #2d3136;
  border: transparent;
  display: block;
  width: 25vw;
  height: 6.2vh;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #fcfcfc;
  padding: 0em 1.4vw;
  border-radius: 6px;
}

.withInputStyle2 {
  background: linear-gradient(91.42deg, #8346c2 -1.9%, #243a9e 95.8%);
  border-radius: 5px;
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  margin-top: 1vh;
}

.noInputStyle2 {
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 5px;
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  margin-top: 1vh;
}

.withInputStyle2 textarea {
  background: #2d3136;
  border: transparent;
  display: block;
  height: 21.3vh;
  width: 25vw;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #fcfcfc;
  padding: 2.2vh 1.4vw;
  border-radius: 6px;
}

.noInputStyle2 textarea {
  background: #2d3136;
  border: transparent;
  display: block;
  height: 21.3vh;
  width: 25vw;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #fcfcfc;
  padding: 2.2vh 1.4vw;
  border-radius: 6px;
}

.inputtext input::placeholder {
  color: #656971;
}

.inputtext textarea::placeholder {
  color: #656971;
}

.solvelabel {
  margin-top: 4vh;
}

.submitbutton,
.submitbutton2 {
  display: inline-block;
  margin-top: 4.4vh;
  padding: 1px;
  text-decoration: none;
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 4px;
  width: 17.2vw;
  height: 6.3vh;
}

.submitbutton2 button {
  background: linear-gradient(97.15deg, #793599 -11.99%, #133a9e 109.23%);
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  border: transparent;
  color: #edeff3;
  width: 17vw;
  height: 6vh;
}

.submitbutton button {
  background: #656971;
  border-radius: 4px;
  display: block;
  width: 17vw;
  height: 6vh;
  font-size: 1rem;
  line-height: 1rem;
  border: none;
  color: #27292d;
}

.submitbutton2 button:hover,
.submitbutton button:hover {
  width: 17.2vw;
  font-size: 1.05rem;
  height: 6.1vh;
  padding-top: -0.2vh;
}

.submitbutton2:hover,
.submitbutton:hover {
  width: 17.2vw;
  height: 6.4vh;
  margin-top: 4.2vh;
}

.contax {
  width: 52vw;
  height: 74vh !important;
  padding: 1px;
  margin-left: 24vw;
  margin-top: 13vh;
}

.cross2 {
  position: absolute;
  right: 2.2vw;
  top: 3.5vh;
  z-index: 999999;
}

.ondone {
  text-align: center;

  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.a {
  display: block;
}

.b {
  display: none;
}

.img {
  text-align: center;

  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;

  width: 33.4vw;
  height: 42.5vh;
}

.ondone h2 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;

  color: #edeff3;
}

.ondone p {
  font-size: 1rem;
  line-height: 1rem;

  color: #939aa9;
}

.ondone span {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  color: #edeff3;
}

.i {
  display: block;
}

.k {
  display: none;
}

#errMsg1::placeholder {
  position: absolute;
  background-color: #4e3333;
  height: 15px;
  margin-top: 7px;
  padding-top: 5px;
  padding: 3px;
  color: #b83939;
}

#errMsg2::placeholder {
  background-color: #4e3333;
  padding: 5px;
  color: #b83939;
}

.btn-close {
  background: transparent url(./Vector.png) center/1em auto no-repeat !important;
}

.modal-backdrop {
  position: fixed;

  z-index: 9999999;
}

@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sut {
  display: block;
}

@media screen and (max-width: 600px) {
  .sut {
    display: none;
  }
  .b-a,
  .bar,
  .bar:before,
  .bar:after {
    transition: all 0.5s;
    content: "";
    position: absolute;
    left: 0;
    height: 0.5vh;
    width: 10vw;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.95);
  }
  .menu-collapsed {
    display: block;
    transition: all 0.5s;
    position: fixed;
    top: 0;
    height: 36px;
    width: 36px;
    z-index: 1;
    cursor: pointer;
  }
  .menu-collapsed ul {
    transition: all 0s;
    position: fixed;
    left: -9000px;
    text-decoration: none;
  }
  .bar2 {
    width: 100vw;
    position: fixed;
    margin-top: -10vh;
    height: 10vh;
    background-color: #24292a;
    box-shadow: 0px 10px 40px rgba(25, 28, 30, 0.3);
  }

  .bar3 {
    background-color: #24292a;
    position: fixed;
    width: 100%;
    height: 10vh;
    box-shadow: 0px 10px 40px rgba(25, 28, 30, 0.3);
  }
  .bar4 {
    background-color: #24292a;
    position: fixed;
    width: 100%;
    z-index: 999999999;
    height: 10vh;
  }

  .bar:before {
    top: -8px;
  }
  .bar:after {
    top: 8px;
  }
  .b-a-expanded,
  .menu-expanded .bar:before,
  .menu-expanded .bar:after {
    transition: all 0.5s;
    top: 0px;
  }
  .menu-expanded {
    position: fixed;
    z-index: 9999999999999;
    transition: all 0.5s;
    text-align: center;
    line-height: 30vh;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    top: 0;
    left: 0;
    background-color: #24292a;
  }
  .menu-expanded ul {
    transition: all 0s;
    text-decoration: none;

    position: relative;
    left: 0;
    z-index: 2;
  }
  .menu-expanded a {
    transition: all 0.15s;
    text-decoration: none;
    width: 90vw;
    height: 25vh;
    font-size: 16px;
    padding: 5px;
    color: #fff;
    display: block;
  }
  .menu-expanded a:hover {
    transition: all 0.15s;
    letter-spacing: 2px;
  }
  .menu-expanded .bar {
    background-color: transparent;
    transition: all 0.25s;
  }
  .menu-expanded .bar:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .menu-expanded .bar:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  .hamburger_continer {
    position: absolute;
    height: 5vh;
    width: 10vw;
    right: 10vw;
    top: 2.5vh;
    display: flex;
    align-items: center;
  }
}

.menu-collapsed {
  display: block;
}
.topbar {
  display: none;
}

.bio {
  text-align: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.anmate-text {
  font-style: normal;
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 3.5rem;
  padding: 50px;
  display: inline-block;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  white-space: nowrap;
  color: #edeff3;
}
.anmate-text span {
  font-family: Caveat;
  font-style: normal;
  font-weight: normal;
  font-size: 4.5rem;
  line-height: 4.5rem;
  color: #656971;
}
.animated3 {
  display: block;
  -webkit-animation: fade linear 0.5s;
          animation: fade linear 0.5s;
}
.animated9 {
  display: block;
  -webkit-animation: fade linear 2s;
          animation: fade linear 2s;
}
.animated4 {
  display: block;
  -webkit-animation: fade2 linear 0.5s;
          animation: fade2 linear 0.5s;
}
.animated {
  display: block;
  -webkit-animation: fade linear 0.5s;
          animation: fade linear 0.5s;
}
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animated2 {
  display: block;
  -webkit-animation: fade2 linear 1s;
          animation: fade2 linear 1s;
}
@-webkit-keyframes fade2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.text-moving {
  -webkit-animation: moveText linear 15s -3s forwards infinite;
          animation: moveText linear 15s -3s forwards infinite;
}

@-webkit-keyframes moveText {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
  }
}

@keyframes moveText {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
  }
}

[data-aos="new"] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
[data-aos="new"].aos-animate {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  [data-aos="new"] {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  [data-aos="new"].aos-animate {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

body {
  position: static;
  width: 100vw;
  overflow-x: hidden;
  background-color: #24292a;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}
* {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.topicon {
  position: fixed;
  z-index: 9999999;
  margin-top: 3.9vh;
  margin-left: 0.5vw;
}
.menu-collapsed {
  display: none;
}
.topbar {
  display: block;
}

.main {
  background-color: #24292a;

  width: 100vw;
}

.background {
  margin-left: 10vw;
  margin-right: 10vw;
}
.navb {
  background-color: #24292a;
  position: fixed;
  width: 100%;
  z-index: 9999999;
  height: 11.9vh;
  box-shadow: 0px 0px 30px rgba(25, 28, 30, 0.5);
}
.navb2 {
  background-color: #24292a;
  position: fixed;
  width: 100%;
  z-index: 9999999;
  height: 11.9vh;
}
.navbars {
  display: flex;
  float: right;
  margin-right: 13vw;
  margin-top: 5.3vh;
  text-decoration: none;
}
.navbars li {
  text-decoration: none;
}
.tap {
  text-decoration: none;
  width: auto;

  margin-left: 6.6vw;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 0.875rem;
  /* identical to box height, or 100% */

  /* Text/Alternate-light-grey */

  color: #edeff3;
}
/* first box*/
.container-one {
  display: flex;
  overflow: hidden;
  height: 100vh;
}
.item-one {
  padding-right: 5%;
  width: 43%;
  font-size: 1rem;
}
.item-two {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;

  overflow: hidden;
}
.item-two img {
  width: 100%;
  height: auto;
  margin-left: -1vw;
}
.t-text {
  font-size: 1em;
  line-height: 1em;
  overflow: hidden;
  color: #939aa9;
}
.bt-n {
  display: flex;
  margin-top: 6.45vh;
}
.contact {
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #656971;
  margin-top: 12vh;
}
.c2 {
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #edeff3;

  -webkit-text-decoration-line: underline;

          text-decoration-line: underline;
}

.item-one h1 {
  margin-top: 30vh;
  font-family: Caveat;

  font-size: 3rem;
  line-height: 3rem;
  color: #edeff3;
  margin-bottom: 2.3vh;
}

.talkbutton {
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 4px;
  font-size: 0.9rem;
  width: 8vw;
  height: 46px;
}

.talkbutton button {
  background: linear-gradient(97.15deg, #793599 -11.99%, #133a9e 109.23%);
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1rem;
  border: transparent;
  color: #edeff3;
  width: 7.7vw;
  height: 46px;
}

.talkbutton button:hover {
  font-size: 1rem;
  width: 7.9vw;
  height: 46px;
}

.talkbutton:hover {
  width: 7.9vw;
  height: 48px;
  margin-right: -0.2vw;
  margin-top: -0.1vh;
}
.b2:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  color: #fcfcfc;
}
.b2 {
  background: transparent;
  border: transparent;
  width: 14.3vw;
  height: 46px;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #bfc6eb;
}

/* secound box*/
.container-two {
  color: #939aa9;
  width: 45vw;

  margin-top: 10.2vh;
}
.container-two h2 {
  width: 43vw;

  font-size: 2rem;
  line-height: 2.75rem;
  /* or 137% */

  /* Text/Light-20 */

  color: #939aa9;
}

.container-three {
  display: flex;
  opacity: 1;
}
.item-3 {
  color: #edeff3;
  opacity: 1;
}
.item-3 h1 {
  width: 30vw;
  height: 52.6vh;
  opacity: 1;

  color: #363a40;
  margin-top: 33.7vh;

  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4.25rem;
  /* or 121% */

  letter-spacing: 0.0025em;
}

.item-3 p {
  width: 38vw;

  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 13vh;

  margin-left: 8.6vw;
  color: #939aa9;
}
.belowtext {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 7.4vh;

  color: #fcfcfc;
}
/*third box*/

.count {
  width: 100% !important;
  text-align: center;
  justify-content: center;
  margin-left: 0vw;
}
.count2 {
  display: none;
}

.countext {
  height: 30vh;
}
.countext h1 {
  font-weight: bold;
  font-size: 4.5rem;
  line-height: 4.5rem;
  /* identical to box height, or 100% */

  /* Text/Light */

  color: #fcfcfc;
}
.countext p {
  font-size: 1rem;
  line-height: 1.25rem;
  /* or 125% */

  text-align: center;

  /* Text/Light-20 */

  color: #939aa9;
}
/*fourth box*/

.box1 {
  position: relative;
}

@media screen and (min-width: 600px) {
  .box1 {
    margin-bottom: 176px;
    margin-left: 5vw;
    width: 648px;
    position: relative;
    height: 575px;
  }
}
.opacityhigh {
  opacity: 1;
}
.opacitylow {
  opacity: 0.1;
}
.b3 {
  margin-left: 33vw;
}
.b3 h2 {
  text-align: right;
  float: right;
}
.containner {
  height: auto;
  width: 100%;
  display: block;
}
.btext {
  height: 7.3vh;
  overflow: hidden;
}
.btext h2 {
  font-size: 1.5em;
  line-height: 2rem;
  margin-top: 2.3vh;
  width: 37.8vw;
  color: #edeff3;
}
.btext span {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #6c778c;
}
.box1 img {
  width: 45vw;
  height: auto;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-self: center;

  justify-content: center;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}
.box1 img:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.box1 div {
  width: 45vw;
  height: auto;
  overflow: hidden;
}

.number {
  font-size: 1.5rem;
  line-height: 1.5rem;
  opacity: 0.5;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* Text/Dark-grey */
  position: absolute;
  right: -3vw;
  bottom: 0vh;
  z-index: 99999999999;
}
.number2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* Text/Dark-grey */
  position: absolute;
  left: -3vw;
  bottom: 0vh;
  z-index: 99999999999;
  opacity: 0.5;
}
.number3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* Text/Dark-grey 

margin-left: 43vw;
*/
  position: absolute;

  right: -3vw;

  bottom: 0vh;
  z-index: 99999999999;
  opacity: 0.5;
}
.number4 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* identical to box height, or 100% */
  color: #6c778c;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* Text/Dark-grey */
  position: absolute;
  left: -3vw;
  bottom: 0vh;
  z-index: 99999999999;
  opacity: 0.5;
}
.ex {
  bottom: 10vh !important;
}
.an {
  margin-top: 40vh;
}
.dis {
  width: 25vw;
  font-size: 1.5rem;
  line-height: 2rem;

  color: #939aa9;
}
.head {
  font-size: 3.5rem;
  line-height: 4.5rem;
  width: 25vw;
  margin-top: 10vh;
  color: #edeff3;
}
.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 6.4vh;
}
.n {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-right: 5.9vw;
  position: static;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-bottom: 1vh;
}
.frame {
  margin-top: 8.4vh;
}
.n button {
  position: static;
  background: transparent;
  border: transparent;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.n button:hover {
  color: #c1c4d6;
}
.invisible {
  display: none;
}
.invisible a {
  display: none;
}
.hoverTopTxt {
  text-align: center;
}
.hoverTopTxt h1 {
  font-size: 1.6rem;
  color: #fcfcfc;
}
.hoverTopTxt p {
  color: #6c778c;
  margin-bottom: 1rem;
  height: 20%;
}
.hoverBg {
  width: 100%;
  background-color: black;
}
.noHoverBg {
  width: 100%;
}
.hoverbutton {
  position: absolute;
  z-index: 999999999;
  height: 100% !important;
  align-items: center;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverbutton2 {
  position: absolute;
  z-index: 999999999;
  top: 33%;
  left: 33%;
}

.hoverbtn button:hover {
  font-size: 0.9rem;
  width: 9.2vw !important;
  height: 46px !important;
}

.hoverbtn:hover {
  font-size: 0.9rem;
  width: 9.4vw !important;
  height: 48px !important;
  background: linear-gradient(90deg, #f0f1f3 -2.1%, rgba(45, 49, 54, 0) 95.8%);
}

.hoverbtn {
  width: 9vw !important;
  height: 46px !important;
  margin-top: 4.5vh;
  border-radius: 5px;
  padding: 1px;
  display: inline-block;
  background: linear-gradient(90deg, #f0f1f3 -1.9%, rgba(45, 49, 54, 0) 95.8%);
}

.hoverbtn button {
  text-decoration: none;
  background: linear-gradient(97.15deg, #793599 -11.99%, #133a9e 109.23%);
  box-shadow: transparent;
  border-radius: 5px;
  font-size: 0.85rem;
  line-height: 1rem;
  height: 100%;
  width: 100%;
  color: #edeff3;
  border: transparent;
}

.hoverbtn a {
  -webkit-text-decoration: transparent;
          text-decoration: transparent;
  width: 13.5vw !important;
  height: 7.7vh !important;
}

.black {
  color: #656971;
}

.white {
  color: #bfc6eb;
  border-bottom: #bfc6eb;
  border: 2px;
}
.an-text {
  margin-top: 2vh;
}

/* 5th fase*/
.interests {
  margin-left: 11vw;
  margin-right: 11vw;
}
.table1 {
  display: flex;
  width: 100%;
}
.one {
  margin-top: 57vh;
  overflow: hidden;
}
.one img {
  width: 17vw !important;
}
.two {
  margin-left: 4vw;
  margin-top: 40vh;
  overflow: hidden;
}
.two img {
  width: 9vw;
}
.three {
  margin-left: 9vw;
  margin-top: 27vh;
  overflow: hidden;
}
.three img {
  width: 12vw;
}
.four {
  margin-left: 3vw;
  margin-top: 55vh;
  overflow: hidden;
}
.four {
  width: 24vw;
}
.table2 {
  margin-top: 9.4vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 65vw;
  margin-left: 7.5vw;
  margin-right: 7.5vw;
}
.table2 h1 {
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: #fcfcfc;
}
.table2 p {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: #939aa9;
}

.table3 {
  display: flex;
}

.five {
  width: 24vw;
  margin-top: 10vh;
  overflow: hidden;
}

.five img {
  width: 24vw;
}

.six {
  width: 24vw;

  overflow: hidden;
  margin-left: 8vw;
  margin-top: 29vh;
}
.six img {
  width: 24vw;
}
.seven {
  width: 16vw;

  margin-left: 6vw;
  margin-top: 5vh;
  overflow: hidden;
}
.seven img {
  width: 16vw;
}

/* footer */
.bottom {
  margin-left: 10vw;
  margin-right: 10vw;
}

.fouter {
  display: flex;

  margin-top: 21.6vh;
}

.fo1 h2 {
  width: 25vw;
  height: 12.7vh;
  font-size: 1.5rem;
  line-height: 2.125rem;

  color: #edeff3;
}
.fo1 p {
  font-size: 1rem;
  line-height: 1rem;
  color: #656971;
}
.idea {
  margin-bottom: 0.9vh;
}
.span {
  font-size: 1.25rem;
  line-height: 1.25rem;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  color: #edeff3;
}
.fo2 p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #7c7f86;
}
.fo2 {
  margin-left: 27vw;
  width: 25vw;
}

.back a {
  text-decoration: none;
  color: #939AA9;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.back img {
  text-align: center;
  margin-left: 12vw;
  width: 1rem;
  margin-bottom: 2px;
}
.icons {
  display: flex;
  margin-top: 3.2vh;
}
.icons a {
  margin-right: 1.7rem;
}
.icons i {
  color: #edeff3;
  font-size: 1.7rem;
}
.icons i:hover {
  color: #7c7f86;
}

.icons img:hover {
  opacity: 0.3;
}
.last {
  text-align: center;
  margin-top: 8.8vh;
}
.copy {
  font-size: 1rem;
  line-height: 1rem;
  /* identical to box height, or 100% */
  text-align: center;
  /* Text/Dark-20 */

  color: #363a40;
}

/*model*/
.modal-content {
  background: #2d3136;
  vertical-align: middle;
  align-items: center;
  justify-self: center;

  border-radius: 20px !important;
}
.model {
  padding: 0;
  border-radius: 20px !important;
}
.modal-fullscreen {
  width: 80vw;
  margin-top: 10vh;
  margin-left: 10vw;
  height: 80vh;
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  padding: 1px;

  box-shadow: -10px -10px 40px rgba(212, 211, 226, 0.15),
    10px 10px 40px rgba(12, 13, 13, 0.7),
    inset -5px -5px 24px rgba(179, 178, 186, 0.05),
    inset 5px 5px 24px rgba(12, 13, 13, 0.25);
  border-radius: 20px !important;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.cross {
  position: absolute;
  right: 2.2vw;
  top: 3.5vh;
  z-index: 99999999999999;
}
.modal-header {
  border: transparent;
}
.modal-footer {
  border: transparent;
}

.submitimg {
  width: 31.4vw;
  height: 42.5vh;

  text-align: center;
  padding: 0;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.modal-body {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}
.from {
  width: 30vw;
  margin-left: 4.4vw;
  margin-top: 5.3vh;
}
.texttop h1 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #edeff3;
}
.top {
  display: none;
}
.im {
  display: flex;
}
.texttop p {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  width: 29.6vw;
  color: #939aa9;
}
.inputtext label {
  font-size: 0.75rem;
  line-height: 0.75rem;
  /* identical to box height, or 100% */

  /* Text/Light-20 */

  color: #939aa9;
}

.withInputStyle1 {
  background: linear-gradient(91.42deg, #8346c2 -1.9%, #243a9e 95.8%);
  border-radius: 5px;
  margin-top: 1vh;
  display: inline-block;
  font-size: 2.4vh;
  padding: 1px;
  text-decoration: none;
}

.noInputStyle1 {
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 5px;
  margin-top: 1vh;
  display: inline-block;
  font-size: 2.4vh;
  padding: 1px;
  text-decoration: none;
}

.noInputStyle1 input {
  background: #2d3136;
  border: transparent;
  display: block;
  width: 25vw;
  height: 6.2vh;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #fcfcfc;
  padding: 0em 1.4vw;
  border-radius: 6px;
}

input:focus {
  background: #2d3136;
  border: transparent;
  outline: none;
}

textarea:focus {
  background: #2d3136;
  border: transparent;
  outline: none;
}

.withInputStyle1 input {
  background: #2d3136;
  border: transparent;
  display: block;
  width: 25vw;
  height: 6.2vh;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #fcfcfc;
  padding: 0em 1.4vw;
  border-radius: 6px;
}

.withInputStyle2 {
  background: linear-gradient(91.42deg, #8346c2 -1.9%, #243a9e 95.8%);
  border-radius: 5px;
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  margin-top: 1vh;
}

.noInputStyle2 {
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 5px;
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  margin-top: 1vh;
}

.withInputStyle2 textarea {
  background: #2d3136;
  border: transparent;
  display: block;
  height: 21.3vh;
  width: 25vw;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #fcfcfc;
  padding: 2.2vh 1.4vw;
  border-radius: 6px;
}

.noInputStyle2 textarea {
  background: #2d3136;
  border: transparent;
  display: block;
  height: 21.3vh;
  width: 25vw;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #fcfcfc;
  padding: 2.2vh 1.4vw;
  border-radius: 6px;
}

.inputtext input::-webkit-input-placeholder {
  color: #656971;
}

.inputtext input::placeholder {
  color: #656971;
}

.inputtext textarea::-webkit-input-placeholder {
  color: #656971;
}

.inputtext textarea::placeholder {
  color: #656971;
}

.solvelabel {
  margin-top: 4vh;
}

.submitbutton,
.submitbutton2 {
  display: inline-block;
  margin-top: 4.4vh;
  padding: 1px;
  text-decoration: none;
  background: linear-gradient(
    91.42deg,
    #f0f1f3 -1.9%,
    rgba(45, 49, 54, 0) 95.8%
  );
  border-radius: 4px;
  width: 17.2vw;
  height: 6.3vh;
}

.submitbutton2 button {
  background: linear-gradient(97.15deg, #793599 -11.99%, #133a9e 109.23%);
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  border: transparent;
  color: #edeff3;
  width: 17vw;
  height: 6vh;
}

.submitbutton button {
  background: #656971;
  border-radius: 4px;
  display: block;
  width: 17vw;
  height: 6vh;
  font-size: 1rem;
  line-height: 1rem;
  border: none;
  color: #27292d;
}

.submitbutton2 button:hover,
.submitbutton button:hover {
  width: 17.2vw;
  font-size: 1.05rem;
  height: 6.1vh;
  padding-top: -0.2vh;
}

.submitbutton2:hover,
.submitbutton:hover {
  width: 17.2vw;
  height: 6.4vh;
  margin-top: 4.2vh;
}

.contax {
  width: 52vw;
  height: 74vh !important;
  padding: 1px;
  margin-left: 24vw;
  margin-top: 13vh;
}

.cross2 {
  position: absolute;
  right: 2.2vw;
  top: 3.5vh;
  z-index: 999999;
}

.ondone {
  text-align: center;

  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.a {
  display: block;
}

.b {
  display: none;
}

.img {
  text-align: center;

  vertical-align: middle;
  align-items: center;
  justify-self: center;
  justify-content: center;

  width: 33.4vw;
  height: 42.5vh;
}

.ondone h2 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;

  color: #edeff3;
}

.ondone p {
  font-size: 1rem;
  line-height: 1rem;

  color: #939aa9;
}

.ondone span {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  color: #edeff3;
}

.i {
  display: block;
}

.k {
  display: none;
}

#errMsg1::-webkit-input-placeholder {
  position: absolute;
  background-color: #4e3333;
  height: 15px;
  margin-top: 7px;
  padding-top: 5px;
  padding: 3px;
  color: #b83939;
}

#errMsg1::placeholder {
  position: absolute;
  background-color: #4e3333;
  height: 15px;
  margin-top: 7px;
  padding-top: 5px;
  padding: 3px;
  color: #b83939;
}

#errMsg2::-webkit-input-placeholder {
  background-color: #4e3333;
  padding: 5px;
  color: #b83939;
}

#errMsg2::placeholder {
  background-color: #4e3333;
  padding: 5px;
  color: #b83939;
}

.btn-close {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACwSURBVHgBlZMxCsMwDEX1Ra/YoVuW0ilniHOGTKFLM5XSQ/UmdmWHhiSVHFtgMJLeQ0YYw/j6EPOjvZ57qojh/u4o+IaJ/CQXlxJ1sJMzISXGpyNwJ8cdTbKC+/Z2cVgKBZI9HHPYNGQkGvwnsCQWrAr2kpQwYFOwkcwCFY7BloD4FNR7yQTrN89d9naQg39j57aDI3ipGRKUwDkJSmFLghpYk3DwvqmBY6ReYYJ85y/dFsAf1wqgUgAAAABJRU5ErkJggg==) center/1em auto no-repeat !important;
}

.modal-backdrop {
  position: fixed;

  z-index: 9999999;
}

@media screen and (max-width: 600px) {
  .topicon {
    position: fixed;
    z-index: 9999999;
    margin-top: 2.5vh;
    margin-left: 0.5vw;
  }

  .menu-collapsed {
    display: block;
  }
  .topbar {
    display: none;
  }
  .an {
    margin-top: 10vh;
  }

  .body {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .backgound {
    width: 100%;
    margin-left: 10vw;
    margin-right: 10vw;
    overflow-x: hidden;
  }
  .main {
    width: 100%;
    position: relative;
    overflow-x: hidden;
  }
  .navb {
    position: fixed;
    display: none;
    width: 100vw;
  }
  .navbar-nav {
    margin-left: 0vw;
  }
  .nav-link {
    width: 0vw;
  }
  .navbar-brand {
    margin-left: 0vw;
  }

  .container-one {
    flex-direction: column-reverse;
    height: auto;
  }

  .item-one {
    width: 100%;
    margin: 0;
  }
  .item-two {
    margin: 0;
    margin-top: 74px;
    width: 100%;
    height: 100%;
  }
  .bt-n {
    width: 100vw;
    margin-top: 10vh;
  }
  .talkbutton {
    width: 101px;
    height: 36px;
  }

  .talkbutton button {
    font-size: 0.75rem;
    line-height: 0.75rem;
    width: 101px;
    height: 36px;
  }

  .talkbutton button:hover {
    font-size: 0.75rem;
    width: 101px;
    height: 36px;
  }

  .talkbutton:hover {
    width: 101px;
    height: 36px;
    margin-right: 0vw;
    margin-top: 0vh;
  }

  .b2 {
    margin: 0;
    padding: 0;
    width: 38.8vw;
    height: 36px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #bfc6eb;
  }
  .contact {
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-left: 0;
    color: #656971;
    margin-top: 12.5vh;
  }

  .item-one h1 {
    margin-top: 4vh;
    margin-bottom: 1.8vh;
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  }
  .c2 {
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-left: 0;
  }
  .item-one p {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: #939aa9;
  }
  .container-two {
    width: 100%;
  }
  .container-two h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
  }

  .container-three {
    flex-direction: column;
  }
  .item-3 {
    width: 100%;
  }
  .item-3 h1 {
    width: 100%;
    height: auto;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 0.0025em;
    color: #363a40;
    margin-top: 6.8vh;
  }

  .item-3 p {
    margin-top: 3.7vh;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 100%;
    margin-left: 0;
    color: #939aa9;
  }

  /*third box*/
  .count2 {
    display: block;
    text-align: center;

    margin-top: 11.2vh;
  }
  .count {
    display: none;
    width: 100% !important;
  }
  .countext {
    height: 25vh;
    width: 28.3vw;
  }
  .countext h1 {
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  }
  .countext p {
    font-size: 0.75rem;
    line-height: 1.125rem;
    width: 28.3vw;
    text-align: left;
  }
  /* fourth box*/
  .box1 {
    width: 100%;
    margin-top: 5vh;
    margin-left: 0;
    height: auto;
  }

  .b3 {
    margin-left: 0vw;
  }
  .b3 h2 {
    float: left;
    text-align: left;
  }
  .box1 h2 {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* identical to box height, or 100% */
    margin-top: 1vh;
    width: 56vw;
    /* Text/Alternate-light-grey */

    color: #edeff3;
  }

  .btext span {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  .number {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .number2 {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .number3 {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .number4 {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .ex {
    top: auto !important;
    bottom: 4vh;
  }
  .btext {
    height: 70px !important;
    margin: 0;
  }
  .box1 img {
    width: 80vw;
    height: auto;

    transition: all 0.3s ease 0s;
    overflow: hidden;
  }
  .box1 div {
    width: 80vw;
    height: auto;
    overflow: hidden;
  }

  .box1 img:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  .dis {
    width: 100%;

    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* or 150% */

    /* Text/Light-20 */

    color: #939aa9;
  }
  .head {
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    /* identical to box height, or 100% */

    /* Text/Alternate-light-grey */

    color: #edeff3;
  }
  .navigation {
    display: none;
    margin-top: 4vh;
  }
  .example-two {
    display: block;
  }

  .n {
    margin: 0;
    margin-right: 0;
  }
  .b2 {
    margin: 0;
  }
  .frame {
    margin-top: 1vh;
  }
  .n button {
    position: static;
    font-size: 11px;
    margin-right: 2vw;
    margin-bottom: 0.5vh;
  }

  .invisible {
    display: none;
  }
  .invisible a {
    display: none;
  }
  .hoverbtn {
    width: 37.5vw !important;
    height: 5.6vh !important;
    padding: 1px 0 1px 1px;
    margin-bottom: 3vh;
  }
  .hoverbtn:nth-child(1) {
    padding: 1px 0 1px 1px !important;
    height: 5.6vh !important;
  }
  .hoverbtn button {
    width: 100% !important;
    height: 100% !important;
    font-size: 11px;
  }
  .hoverbtn a {
    width: 28.5vw !important;
    height: 6vh !important;
  }
  .hoverbtn button:hover {
    font-size: 12px;
  }

  .system {
    width: 90vw;
  }

  .bio {
    padding: 5px;
    margin: 0;
  }
  .an-text {
    margin-top: 72px;
  }
  .anmate-text {
    font-weight: 300;
    padding: 10px;
    color: #edeff3;
  }
  .anmate-text span {
    font-family: Caveat;
    font-size: 22px;
    line-height:1rem;
    color: #656971;
  }

  /* intersest*/
  .interests {
    margin-left: 11vw;
    margin-right: 11vw;
  }
  .table1 {
    display: flex;
    width: 100%;
  }
  .one {
    margin-top: 20vh;
    overflow: hidden;
  }
  .one img {
    width: 18vw !important;
  }
  .two {
    margin-left: 4vw;
    margin-top: 12vh;
    overflow: hidden;
  }
  .two img {
    width: 10vw;
  }
  .three {
    margin-left: 5vw;
    margin-top: 8vh;
    overflow: hidden;
  }
  .three img {
    width: 13vw;
  }
  .four {
    margin-left: 3vw;
    margin-top: 18vh;
    overflow: hidden;
  }
  .four {
    width: 24vw;
  }
  .table2 {
    margin-top: 5.4vh;

    width: 65vw;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
  }
  .table2 h1 {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .table2 p {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .five {
    width: 24vw;

    margin-top: 4vh;
    overflow: hidden;
  }
  .five img {
    width: 24vw;
  }
  .six {
    width: 24vw;

    overflow: hidden;
    margin-left: 8vw;
    margin-top: 10vh;
  }
  .six img {
    width: 24vw;
  }
  .seven {
    width: 16vw;

    margin-left: 6vw;
    margin-top: 4vh;
    overflow: hidden;
  }
  .seven img {
    width: 16vw;
  }
  /*footer*/
  .fouter {
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-top: 88px;

    vertical-align: middle;
    align-items: center;
    justify-self: center;
    justify-content: center;
  }
  .fo1 {
    width: 100%;
    margin: 0;
    margin-bottom: 44px;
    text-align: center;
    vertical-align: middle;
    justify-self: center;
    justify-content: center;
  }
  .fo2 {
    width: 100%;
    margin-top: 5vh;
    margin: 0;
    margin-left: 0vw !important;
    text-align: center;
    vertical-align: middle;

    justify-self: center;
    justify-content: center;
  }

  .fo1 h2 {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    width: 100%;
    text-align: center;
    color: #edeff3;
    font-weight: 400;
  }
  .fo1 p {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    margin: 0;
  }
  .span {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin: 0;
    color: #edeff3;
  }
  .fo2 p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    text-align: center;
    justify-content: center;
    display: block;
    color: #7c7f86;
    font-weight: 400;
  }
  .icons {
    display: flex;

    justify-content: center;
  }
  .icons a {
    text-align: center;
    display: block;
  }
  .icons i {
    font-size: 1.5rem;
    text-align: center;
    padding-left: 2vw;
  }

  .back a {
    text-decoration: none;
    color: #939AA9;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  
  .back img {
    text-align: center;
    margin-left: 0vw;
    width: 14px;
    margin-bottom: 1px;
  }

  .last {
    text-align: center;
    padding-bottom: 0vh;
  }
  .copy {
    font-size: 12px;
    line-height: 12px;
    margin-left: 0vw;
    text-align: center;
    color: #363a40;
  }

  .anmate-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1rem;
  }
  /* model*/
  .modal-content {
    background: #2d3136;
    border-radius: 20px;
    width: 80vw;
    height: 84.3vh;
    box-shadow: none;
    vertical-align: middle;
    align-items: right;
    justify-self: right;
  }
  .model {
    padding: 0;
  }
  .modal-fullscreen {
    width: 80vw !important;
    height: 84vh;
    margin: 8vh 10vw;
  }
  .top {
    display: block;
  }
  .top h6 {
    width: 40vw;
    height: 6.5vh;
    margin-left: 2vw;
    margin-top: 3.5vh;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #edeff3;
    color: #edeff3;
  }
  .modal-header {
    margin: 0;
    height: 0px;
  }

  .modal-body img {
    width: 30vw;
    height: 12.8vh;
    margin-top: 0px;
    padding: 0 !important;
  }
  .modal-body {
    flex-direction: column;
  }
  .from {
    width: 75vw;
    margin-top: 0vh;
    margin-left: 0vw;
  }
  .texttop {
    display: none;
  }
  .inputtext {
    margin-top: 0vh;
  }

  .buttoncolor {
    margin-bottom: 0px;
    width: 70vw;
  }
  .buttoncolor input {
    padding: 0em 1em;
    width: 70vw;
  }

  .button {
    background: linear-gradient(
      91.42deg,
      #f0f1f3 -1.9%,
      rgba(45, 49, 54, 0) 95.8%
    );
    border-radius: 5px;
    width: 68vw;
    display: inline-block;
    font-size: 1.25rem;
    padding: 1px;
    text-decoration: none;
  }
  .button input {
    background: #2d3136;
    border: none;
    display: block;
    width: 70vw;
    height: 6.2vh;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #fcfcfc;
    padding-left: 1em;
    border-radius: 5px;
  }
  .buttoncolor2 textarea {
    width: 70vw;
    padding-left: 1em;
    height: 30vh;
  }
  .button2 {
    background: linear-gradient(
      91.42deg,
      #f0f1f3 -1.9%,
      rgba(45, 49, 54, 0) 95.8%
    );
    border-radius: 5px;
    width: 68vw;
    display: inline-block;
    font-size: 1.25rem;
    padding: 1px;
    text-decoration: none;
  }
  .button2 textarea {
    background: #2d3136;
    border: transparent;
    display: block;
    height: 30vh;
    width: 70vw;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #fcfcfc;
    padding-left: 1em;
    border-radius: 5px;
  }

  .inputtext input::-webkit-input-placeholder {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #656971;
  }

  .inputtext input::placeholder {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #656971;
  }

  .inputtext textarea::-webkit-input-placeholder {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #656971;
  }

  .inputtext textarea::placeholder {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #656971;
  }

  .submitbutton,
  .submitbutton2:hover,
  .submitbutton:hover,
  .submitbutton2 {
    width: 176px;
    height: 36px;
    padding: 1px 0 1px 1px;
    border-radius: 4px;
  }

  .submitbutton button,
  .submitbutton2 button:hover,
  .submitbutton button:hover,
  .submitbutton2 button {
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
  }
  .ondone .submitbutton2 {
    margin-top: 9vh;
  }
  .contax {
    width: 80vw !important;
    background: transparent !important;
    box-shadow: transparent;
    margin-left: 10vw;
    height: 85vh !important;
  }

  .cross2 {
    position: absolute;
    right: 3.4vw;
    top: 6.1vw;
    z-index: 9999999999;
  }
  .cross {
    position: absolute;
    left: 70vw;
    top: 2.3vh;
    z-index: 999999999;
  }

  .ondone img {
    width: 68.3vw !important;
    height: 32.5vh !important;
  }

  .ondone h2 {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    margin-top: 2.5vh;
    text-align: center;
  }
  .ondone p {
    margin-top: 3.4vh;
    font-size: 0.625rem;
    line-height: 0.625rem;
    color: #939aa9;
  }
  .no {
    padding-top: 0.9vh;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    display: block;

    color: #edeff3;
  }

  .imageClipper img {
    width: 100%;
    height: auto;
  }

  .noInputStyle1 input, .withInputStyle1 input {
    width: 256px;
    height: 40px;
  }

  .noInputStyle2 textarea, .withInputStyle2 textarea {
    height: 192px;
    width: 256px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1440px) { 
    .talkbutton {
        width: 141px;
        height: 46px;
    }
    
    .talkbutton button {
        width: 141px;
        height: 46px;
    }

    .talkbutton button:hover {
        font-size: 1rem;
        width: 144px;
        height: 46px;
    }

    .talkbutton:hover {
        width: 144px;
        height: 48px;
    }

    .hoverbtn {
        width: 169px !important;
        height: 46px !important;
    }

    .hoverbtn button:hover {
        width: 169vw !important;
        height: 46px !important;
    }
      
    .hoverbtn:hover {
        width: 171px !important;
        height: 48px !important;
    }
}

.example-two{
    display: none;
}
@media screen and (max-width: 600px){
    .example-two{
        display: block;
    } 
}


.scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.example-two-header .logo {
    width: 0%;
}
.example-two-header nav {
    width: 100%;
}
.example-three .logo, .example-three nav {
    width: 100%;
}
.example-three .nav-item {
    color: #f6f7ee;
}
header {
    background: transparent;
}
.example-one-header, .example-two-header {
    border-radius: 3px;
}
.example-three-header {
    border-radius: 3px 3px 0 0;
}
.example-three nav {
    background: #727c87;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    border-radius: 0 0 3px 3px;
}
.logo {
    text-align: center;
    font-weight: 700;
    color: #727c87;
    border-right: 1px solid rgba(114, 124, 135, .4);
    padding: 12px 24px 13px;
}
.nav-item {
    padding: 12px 16px 13px;
}

.logo, .nav-item {
    font-size: 14px;
}
.logo, .nav-item, .vertical-align-middle {
    display: inline-block;
    vertical-align: middle;
}
.title {
    margin: 24px 0 6px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #999;
}
.scroll::-webkit-scrollbar {
    display: none;
}


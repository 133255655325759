@media screen and (max-width: 600px) {
  .topicon {
    position: fixed;
    z-index: 9999999;
    margin-top: 2.5vh;
    margin-left: 0.5vw;
  }

  .menu-collapsed {
    display: block;
  }
  .topbar {
    display: none;
  }
  .an {
    margin-top: 10vh;
  }

  .body {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .backgound {
    width: 100%;
    margin-left: 10vw;
    margin-right: 10vw;
    overflow-x: hidden;
  }
  .main {
    width: 100%;
    position: relative;
    overflow-x: hidden;
  }
  .navb {
    position: fixed;
    display: none;
    width: 100vw;
  }
  .navbar-nav {
    margin-left: 0vw;
  }
  .nav-link {
    width: 0vw;
  }
  .navbar-brand {
    margin-left: 0vw;
  }

  .container-one {
    flex-direction: column-reverse;
    height: auto;
  }

  .item-one {
    width: 100%;
    margin: 0;
  }
  .item-two {
    margin: 0;
    margin-top: 74px;
    width: 100%;
    height: 100%;
  }
  .bt-n {
    width: 100vw;
    margin-top: 10vh;
  }
  .talkbutton {
    width: 101px;
    height: 36px;
  }

  .talkbutton button {
    font-size: 0.75rem;
    line-height: 0.75rem;
    width: 101px;
    height: 36px;
  }

  .talkbutton button:hover {
    font-size: 0.75rem;
    width: 101px;
    height: 36px;
  }

  .talkbutton:hover {
    width: 101px;
    height: 36px;
    margin-right: 0vw;
    margin-top: 0vh;
  }

  .b2 {
    margin: 0;
    padding: 0;
    width: 38.8vw;
    height: 36px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #bfc6eb;
  }
  .contact {
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-left: 0;
    color: #656971;
    margin-top: 12.5vh;
  }

  .item-one h1 {
    margin-top: 4vh;
    margin-bottom: 1.8vh;
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  }
  .c2 {
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-left: 0;
  }
  .item-one p {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: #939aa9;
  }
  .container-two {
    width: 100%;
  }
  .container-two h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
  }

  .container-three {
    flex-direction: column;
  }
  .item-3 {
    width: 100%;
  }
  .item-3 h1 {
    width: 100%;
    height: auto;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 0.0025em;
    color: #363a40;
    margin-top: 6.8vh;
  }

  .item-3 p {
    margin-top: 3.7vh;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 100%;
    margin-left: 0;
    color: #939aa9;
  }

  /*third box*/
  .count2 {
    display: block;
    text-align: center;

    margin-top: 11.2vh;
  }
  .count {
    display: none;
    width: 100% !important;
  }
  .countext {
    height: 25vh;
    width: 28.3vw;
  }
  .countext h1 {
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  }
  .countext p {
    font-size: 0.75rem;
    line-height: 1.125rem;
    width: 28.3vw;
    text-align: left;
  }
  /* fourth box*/
  .box1 {
    width: 100%;
    margin-top: 5vh;
    margin-left: 0;
    height: auto;
  }

  .b3 {
    margin-left: 0vw;
  }
  .b3 h2 {
    float: left;
    text-align: left;
  }
  .box1 h2 {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* identical to box height, or 100% */
    margin-top: 1vh;
    width: 56vw;
    /* Text/Alternate-light-grey */

    color: #edeff3;
  }

  .btext span {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  .number {
    transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .number2 {
    transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .number3 {
    transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .number4 {
    transform: rotate(0deg);
    top: auto;
    bottom: 4vh;
    left: 76vw;
  }
  .ex {
    top: auto !important;
    bottom: 4vh;
  }
  .btext {
    height: 70px !important;
    margin: 0;
  }
  .box1 img {
    width: 80vw;
    height: auto;

    transition: all 0.3s ease 0s;
    overflow: hidden;
  }
  .box1 div {
    width: 80vw;
    height: auto;
    overflow: hidden;
  }

  .box1 img:hover {
    transform: scale(1);
  }

  .dis {
    width: 100%;

    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* or 150% */

    /* Text/Light-20 */

    color: #939aa9;
  }
  .head {
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    /* identical to box height, or 100% */

    /* Text/Alternate-light-grey */

    color: #edeff3;
  }
  .navigation {
    display: none;
    margin-top: 4vh;
  }
  .example-two {
    display: block;
  }

  .n {
    margin: 0;
    margin-right: 0;
  }
  .b2 {
    margin: 0;
  }
  .frame {
    margin-top: 1vh;
  }
  .n button {
    position: static;
    font-size: 11px;
    margin-right: 2vw;
    margin-bottom: 0.5vh;
  }

  .invisible {
    display: none;
  }
  .invisible a {
    display: none;
  }
  .hoverbtn {
    width: 37.5vw !important;
    height: 5.6vh !important;
    padding: 1px 0 1px 1px;
    margin-bottom: 3vh;
  }
  .hoverbtn:nth-child(1) {
    padding: 1px 0 1px 1px !important;
    height: 5.6vh !important;
  }
  .hoverbtn button {
    width: 100% !important;
    height: 100% !important;
    font-size: 11px;
  }
  .hoverbtn a {
    width: 28.5vw !important;
    height: 6vh !important;
  }
  .hoverbtn button:hover {
    font-size: 12px;
  }

  .system {
    width: 90vw;
  }

  .bio {
    padding: 5px;
    margin: 0;
  }
  .an-text {
    margin-top: 72px;
  }
  .anmate-text {
    font-weight: 300;
    padding: 10px;
    color: #edeff3;
  }
  .anmate-text span {
    font-family: Caveat;
    font-size: 22px;
    line-height:1rem;
    color: #656971;
  }

  /* intersest*/
  .interests {
    margin-left: 11vw;
    margin-right: 11vw;
  }
  .table1 {
    display: flex;
    width: 100%;
  }
  .one {
    margin-top: 20vh;
    overflow: hidden;
  }
  .one img {
    width: 18vw !important;
  }
  .two {
    margin-left: 4vw;
    margin-top: 12vh;
    overflow: hidden;
  }
  .two img {
    width: 10vw;
  }
  .three {
    margin-left: 5vw;
    margin-top: 8vh;
    overflow: hidden;
  }
  .three img {
    width: 13vw;
  }
  .four {
    margin-left: 3vw;
    margin-top: 18vh;
    overflow: hidden;
  }
  .four {
    width: 24vw;
  }
  .table2 {
    margin-top: 5.4vh;

    width: 65vw;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
  }
  .table2 h1 {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .table2 p {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .five {
    width: 24vw;

    margin-top: 4vh;
    overflow: hidden;
  }
  .five img {
    width: 24vw;
  }
  .six {
    width: 24vw;

    overflow: hidden;
    margin-left: 8vw;
    margin-top: 10vh;
  }
  .six img {
    width: 24vw;
  }
  .seven {
    width: 16vw;

    margin-left: 6vw;
    margin-top: 4vh;
    overflow: hidden;
  }
  .seven img {
    width: 16vw;
  }
  /*footer*/
  .fouter {
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-top: 88px;

    vertical-align: middle;
    align-items: center;
    justify-self: center;
    justify-content: center;
  }
  .fo1 {
    width: 100%;
    margin: 0;
    margin-bottom: 44px;
    text-align: center;
    vertical-align: middle;
    justify-self: center;
    justify-content: center;
  }
  .fo2 {
    width: 100%;
    margin-top: 5vh;
    margin: 0;
    margin-left: 0vw !important;
    text-align: center;
    vertical-align: middle;

    justify-self: center;
    justify-content: center;
  }

  .fo1 h2 {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    width: 100%;
    text-align: center;
    color: #edeff3;
    font-weight: 400;
  }
  .fo1 p {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    margin: 0;
  }
  .span {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin: 0;
    color: #edeff3;
  }
  .fo2 p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    text-align: center;
    justify-content: center;
    display: block;
    color: #7c7f86;
    font-weight: 400;
  }
  .icons {
    display: flex;

    justify-content: center;
  }
  .icons a {
    text-align: center;
    display: block;
  }
  .icons i {
    font-size: 1.5rem;
    text-align: center;
    padding-left: 2vw;
  }

  .back a {
    text-decoration: none;
    color: #939AA9;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  
  .back img {
    text-align: center;
    margin-left: 0vw;
    width: 14px;
    margin-bottom: 1px;
  }

  .last {
    text-align: center;
    padding-bottom: 0vh;
  }
  .copy {
    font-size: 12px;
    line-height: 12px;
    margin-left: 0vw;
    text-align: center;
    color: #363a40;
  }

  .anmate-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1rem;
  }
  /* model*/
  .modal-content {
    background: #2d3136;
    border-radius: 20px;
    width: 80vw;
    height: 84.3vh;
    box-shadow: none;
    vertical-align: middle;
    align-items: right;
    justify-self: right;
  }
  .model {
    padding: 0;
  }
  .modal-fullscreen {
    width: 80vw !important;
    height: 84vh;
    margin: 8vh 10vw;
  }
  .top {
    display: block;
  }
  .top h6 {
    width: 40vw;
    height: 6.5vh;
    margin-left: 2vw;
    margin-top: 3.5vh;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #edeff3;
    color: #edeff3;
  }
  .modal-header {
    margin: 0;
    height: 0px;
  }

  .modal-body img {
    width: 30vw;
    height: 12.8vh;
    margin-top: 0px;
    padding: 0 !important;
  }
  .modal-body {
    flex-direction: column;
  }
  .from {
    width: 75vw;
    margin-top: 0vh;
    margin-left: 0vw;
  }
  .texttop {
    display: none;
  }
  .inputtext {
    margin-top: 0vh;
  }

  .buttoncolor {
    margin-bottom: 0px;
    width: 70vw;
  }
  .buttoncolor input {
    padding: 0em 1em;
    width: 70vw;
  }

  .button {
    background: linear-gradient(
      91.42deg,
      #f0f1f3 -1.9%,
      rgba(45, 49, 54, 0) 95.8%
    );
    border-radius: 5px;
    width: 68vw;
    display: inline-block;
    font-size: 1.25rem;
    padding: 1px;
    text-decoration: none;
  }
  .button input {
    background: #2d3136;
    border: none;
    display: block;
    width: 70vw;
    height: 6.2vh;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #fcfcfc;
    padding-left: 1em;
    border-radius: 5px;
  }
  .buttoncolor2 textarea {
    width: 70vw;
    padding-left: 1em;
    height: 30vh;
  }
  .button2 {
    background: linear-gradient(
      91.42deg,
      #f0f1f3 -1.9%,
      rgba(45, 49, 54, 0) 95.8%
    );
    border-radius: 5px;
    width: 68vw;
    display: inline-block;
    font-size: 1.25rem;
    padding: 1px;
    text-decoration: none;
  }
  .button2 textarea {
    background: #2d3136;
    border: transparent;
    display: block;
    height: 30vh;
    width: 70vw;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #fcfcfc;
    padding-left: 1em;
    border-radius: 5px;
  }

  .inputtext input::placeholder {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #656971;
  }

  .inputtext textarea::placeholder {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #656971;
  }

  .submitbutton,
  .submitbutton2:hover,
  .submitbutton:hover,
  .submitbutton2 {
    width: 176px;
    height: 36px;
    padding: 1px 0 1px 1px;
    border-radius: 4px;
  }

  .submitbutton button,
  .submitbutton2 button:hover,
  .submitbutton button:hover,
  .submitbutton2 button {
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
  }
  .ondone .submitbutton2 {
    margin-top: 9vh;
  }
  .contax {
    width: 80vw !important;
    background: transparent !important;
    box-shadow: transparent;
    margin-left: 10vw;
    height: 85vh !important;
  }

  .cross2 {
    position: absolute;
    right: 3.4vw;
    top: 6.1vw;
    z-index: 9999999999;
  }
  .cross {
    position: absolute;
    left: 70vw;
    top: 2.3vh;
    z-index: 999999999;
  }

  .ondone img {
    width: 68.3vw !important;
    height: 32.5vh !important;
  }

  .ondone h2 {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    margin-top: 2.5vh;
    text-align: center;
  }
  .ondone p {
    margin-top: 3.4vh;
    font-size: 0.625rem;
    line-height: 0.625rem;
    color: #939aa9;
  }
  .no {
    padding-top: 0.9vh;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    display: block;

    color: #edeff3;
  }

  .imageClipper img {
    width: 100%;
    height: auto;
  }

  .noInputStyle1 input, .withInputStyle1 input {
    width: 256px;
    height: 40px;
  }

  .noInputStyle2 textarea, .withInputStyle2 textarea {
    height: 192px;
    width: 256px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1440px) { 
    .talkbutton {
        width: 141px;
        height: 46px;
    }
    
    .talkbutton button {
        width: 141px;
        height: 46px;
    }

    .talkbutton button:hover {
        font-size: 1rem;
        width: 144px;
        height: 46px;
    }

    .talkbutton:hover {
        width: 144px;
        height: 48px;
    }

    .hoverbtn {
        width: 169px !important;
        height: 46px !important;
    }

    .hoverbtn button:hover {
        width: 169vw !important;
        height: 46px !important;
    }
      
    .hoverbtn:hover {
        width: 171px !important;
        height: 48px !important;
    }
}